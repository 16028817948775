import { executeRecaptcha } from '@vo/js/modules/recaptcha'
import validateEmail from '@vo/js/utils/validateEmail'
import { __ } from '@vo/js/utils/i18n'
import { gtmHandleFormSubmit, gtmHandleFormError } from '@vo/js/modules/tagManagerEvents'

/**
 * Handle ajax form submission success
 * @param {HTMLFormElement} form
 * @private
 */
const _handleAjaxFormSuccess = (form) => {
    const successNotice = form.querySelector('[data-form-success]')
    if (successNotice) {
        successNotice.classList.remove('hidden')
        form.querySelector('[data-form-wrapper]')?.remove()
    }

    gtmHandleFormSubmit(form)

    communicateFormResultToVwo(form, true)
}

/**
 * Handle ajax form submission error
 * @param {HTMLFormElement} form
 * @param {Object} data
 * @private
 */
const _handleAjaxFormError = (form, data) => {
    const errorNotice = form.querySelector('[data-form-error]')
    if (errorNotice) {
        let error = data.error || ''
        const errors = data.errors || []

        if (errors.length) {
            error = ''
            errors.forEach((err) => error += `${err}<br>`)
        }

        errorNotice.innerHTML = error
        errorNotice.classList.remove('hidden')
        gtmHandleFormError(form, errors)

        communicateFormResultToVwo(form, false)
    }

    form.querySelector('button[type=submit]')?.removeAttribute('disabled')
}

/**
 * Handle ajax form submission
 * @param {HTMLFormElement} form
 * @returns {Promise<void>}
 * @private
 */
const _submitAjaxForm = async (form) => {
    const formData = new FormData(form)

    try {
        const res = await axios.request({
            method: form.getAttribute('method') || 'post',
            url: form.getAttribute('action') || window.location.href,
            data: formData,
            headers: {
                'Cache-Control': 'no-cache'
            }
        })

        const data = res.data

        if (data.success) {
            _handleAjaxFormSuccess(form)
        } else {
            _handleAjaxFormError(form, data)
        }
    } catch (err) {
        _handleAjaxFormError(form, err.response.data)
    }
}

/**
 * Submit form
 * @param {HTMLFormElement} form
 */
export const submitForm = (form) => {
    if (!form) {
        return
    }

    if (form.hasAttribute('data-form-ajax')) {
        _submitAjaxForm(form)
    } else {
        form.submit()
    }
}

//** Form input methods **//

/**
 * Set up form events and debounce
 */
const setupForms = () => {
    const forms = document.querySelectorAll('form:not([data-nitro-form]):not([data-nitro-fragment])')

    /**
     * Debounce form submissions to prevent double submissions.
     * @param form
     */
    const debounceForm = (form) => {
        let debounce

        form.addEventListener('submit', (e) => {
            e.preventDefault()

            if (debounce) {
                clearTimeout(debounce)
            }

            debounce = setTimeout(() => {
                form.querySelector('button[type=submit]')?.setAttribute('disabled', 'disabled')

                if (form.querySelector('[data-recaptcha]') && document.querySelector('.g-recaptcha')) {
                    executeRecaptcha(form)
                } else {
                    submitForm(form)
                }
            }, 100)
        })
    }

    const handleForms = () => {
        if (!forms) return

        forms.forEach((form) => {
            debounceForm(form)
        })
    }

    const init = () => {
        handleForms()
    }

    init()
}

/**
 * Check if required fields have a value
 */
const required = () => {
    const requiredFields = document.querySelectorAll('input[required], textarea[required]')

    requiredFields.forEach((field) => {
        field.addEventListener('focusout', () => {
            if (!field.value || !field.value.trim().length) {
                field.value = ''
                error(field, true, __('This field cannot be blank.'))
            } else {
                error(field, false)
            }
        })
    })
}

/**
 * Validate specific fields like email
 */
const validation = () => {
    const validationFields = document.querySelectorAll('input[type="email"]')

    validationFields.forEach((field) => {
        field.addEventListener('focusout', () => {
            const type = field.getAttribute('type')

            if (type === 'email') {
                error(field, !validateEmail(field.value), __('Please enter a valid email address.'))
            }
        })
    })
}

/**
 * Handle error state and message
 * @param {Element} field
 * @param {null|Boolean} error
 * @param {String} message
 */
const error = (field = null, error = false, message = '') => {
    if (error) {
        field.classList.add('error')

        if (message) {
            field.closest('.input-field').querySelector('.input-validation-message').innerHTML = message
        }
    } else {
        field.classList.remove('error')
    }
}

/**
 * Handle focus state
 */
const focus = () => {
    const fields = document.querySelectorAll('.input-field input, .input-field textarea')

    fields.forEach((field) => {
        field.addEventListener('focusin', () => {
            field.closest('.input-field').classList.add('focused')
        })

        field.addEventListener('focusout', () => {
            field.closest('.input-field').classList.remove('focused')
        })
    })
}

/**
 * Handle filled state
 */
const filled = () => {
    const fields = document.querySelectorAll('.input-field input, .input-field textarea')

    fields.forEach((field) => {
        if (field.value) {
            field.closest('.input-field').classList.add('filled')
        } else {
            field.closest('.input-field').classList.remove('filled')
        }

        field.addEventListener('focusout', () => {
            if (field.value) {
                field.closest('.input-field').classList.add('filled')
            } else {
                field.closest('.input-field').classList.remove('filled')
            }
        })

        field.addEventListener('change', () => {
            if (field.value) {
                field.closest('.input-field').classList.add('filled')
            } else {
                field.closest('.input-field').classList.remove('filled')
            }
        })
    })
}

const communicateFormResultToVwo = (form, success) => {
    window.VWO = window.VWO || []

    if (success) {
        window.VWO.push(['nls.formAnalysis.markSuccess', form, 1])
    } else {
        window.VWO.push(['nls.formAnalysis.markSuccess', form, 0])
    }
}

export default (function () {
    setupForms()
    required()
    validation()
    focus()
    filled()
})
